<template>
    <div>
        <div class='flex items-end mt-6 mb-6'>
            <div>
                <CustomTitle class='m-0'>
                    {{ $t('ttl-organization-connections') }}
                </CustomTitle>
            </div>
        </div>
        <div class='flex mt-6 mb-6'>
            <p class='text-gray-500'>
                {{ $t('txt-sync-connection-info') }}
            </p>
        </div>
        <ContentBox>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn> {{ $t('lbl-sync-connection-name') }}</ListColumn>
                    <ListColumn> {{ $t('lbl-sync-connection-id') }}</ListColumn>
                    <ListColumn>
                        {{ $t('lbl-sync-connection-token') }}
                    </ListColumn>
                    <ListColumn>{{ $t('col-organization-consents-actions') }}</ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(pod, i) in pods' :key='i'>
                        <ListItem>
                            {{ pod.name }}
                        </ListItem>
                        <ListItem>
                            {{ pod.id }}
                        </ListItem>
                        <ListItem>
                            <button
                                class='btn btn-sm btn-default px-2 '
                                @click.prevent='copyToClipboard(pod.token)'
                            >
                                <span class='font-mono uuid align-middle'> {{ pod.token ? pod.token : "Press the button for token" }}</span>
                                <i class='fa fa-clipboard ml-1'></i>
                            </button>
                        </ListItem>
                        <ListItem>
                            <FriendlyButton
                                symbol='refresh'
                                extra-small
                                no-margin
                                square
                                label='Refresh token'
                                :action='() => generateToken(pod)'
                            />
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>
    </div>
</template>

<script>
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ContentBox from '@/components/ContentBox';
    import notify from '@/notify.js';
    import CustomTitle from '@/components/Title';
    import { gql } from '@apollo/client/core';
    import { simpleLocale } from '@/i18n';

    export default {
        name: 'OrganizationUsersView',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        components: {
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
            ContentBox,
            CustomTitle,
        },
        data () {
            return {
                loading: false,
                pods: [],
            };
        },
        computed: {
            language () {
                return simpleLocale(this.$i18n.locale);
            },
        },
        mounted () {
            this.fetchList();
        },
        methods: {
            async fetchList () {
                this.loading = true;
                this.pods = await this.getPods();
                this.loading = false;
            },
            async generateToken (pod) {
                try {
                    const results = await this.$apollo.query({
                        query: gql`query createSyncActivation($podUuid: String) {
                            createSyncActivation(podUuid: $podUuid) {
                                token
                            }
                        }`,
                        variables: {
                            podUuid: pod.id,
                        },
                    });
                    pod.token = results.data.createSyncActivation.token;
                    return results.data.createSyncActivation.token;
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                } finally {
                    this.loading = false;
                }
            },
            async getPods () {
                try {
                    const results = await this.$apollo.query({
                        query: gql`query getPodsByOrganization($legalEntityUuid: String, $type: String ) {
                            getPodsByOrganization(legalEntityUuid: $legalEntityUuid, type: $type) {
                                id
                                name
                            }
                        }`,
                        variables: {
                            legalEntityUuid: this.organization.id,
                            type: 'sync',
                        },
                    });
                    return results.data.getPodsByOrganization;
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                } finally {
                    this.loading = false;
                }
            },
            async copyToClipboard (uuid) {
                try {
                    await navigator.clipboard.writeText(uuid);
                    notify.success(this.$t('p-uuid-copied-clipboard-success'));
                } catch (err) {
                    notify.error(this.$t('p-uuid-copied-clipboard-error'));
                }
            },
        },
    };
</script>

<style scoped>
.uuid {
    display: inline-block;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
