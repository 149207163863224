<script>
    import { gql } from '@apollo/client/core';
    import Popup from '@/clientcomponents/Popup';
    import ClientAddCoda from '../components/ClientAddCoda';
    import CodaCard from '../components/CodaCard';
    import utils from '@/utils';
    import ResendCodaButton from '@/fiduciary/components/ResendCodaButton';
    import Tooltip from '@/components/Tooltip.vue';
    import Loader from '@/loader';
    import notify from '@/notify';
    import validate from '@/validate';
    import ContentBox from '@/components/ContentBox';
    import SendSodaCodaByMail from './components/SendSodaCodaByMail';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { Form } from 'vee-validate';

    export default {
        name: 'ClientCoda',
        props: {
            clientV2: { type: Object, required: true },
            currentEnvironment: { type: Object, required: true },
        },
        components: {
            Popup,
            ClientAddCoda,
            CodaCard,
            ResendCodaButton,
            Tooltip,
            ContentBox,
            SendSodaCodaByMail,
            FriendlyButton,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
        },
        data () {
            return {
                highlightedCodaMandateId: null,
                saving: false,
                codaMandates: null,
                newBankAccounts: [],
                codaLoaded: false,
                showOrderCodaMandatePopup: false,
            };
        },
        apollo: {
            currentVoilaMandate: {
                query: gql`query VoilaMandate($clientId: String) {
                    currentVoilaMandate(clientId:$clientId) {
                         ibans {
                            value
                         }
                    }
                }`,
                variables () {
                    return { clientId: this.clientV2.id };
                },
            },
        },
        computed: {
            bankAccounts () {
                if (!this.codaMandates) {
                    return [];
                }
                let bankAccounts = [];
                for (let mandate of this.codaMandates) {
                    let bankName = mandate.bank.name;
                    bankAccounts = bankAccounts.concat(
                        mandate.bankAccounts.map(
                            ba => ({bankName, ...ba}),
                        ),
                    );
                }
                return bankAccounts;
            },
            invalidRepresentativeName () {
                return !validate.isComposedName(this.clientV2.representativeName);
            },
            missingContactEmail () {
                return !this.clientV2.contactEmail;
            },
        },
        async beforeMount () {
            await this.getCodaMandates();
            await this.getCodaRedeliveryRequests(this.clientV2.id, this.codaMandates.map(mandate => { return mandate.id; }));
        },
        async mounted () {
            this.highlightCodaMandate();
        },
        emits: ['clientupdated'],
        methods: {
            highlightCodaMandate () {
                const queryParams = { ...this.$route.query };
                if (queryParams.highlightedCodaMandateId) {
                    this.highlightedCodaMandateId = queryParams.highlightedCodaMandateId;

                    // clean query param from the url (avoid highlight at refresh or if page is bookmarked)
                    delete queryParams.highlightedCodaMandateId;
                    this.$router.replace({ query: queryParams });
                }
            },
            async getCodaMandates () {
                Loader.start();
                const { data } = await this.$apollo.query({
                    query: gql`query getCodaMandate($clientId: String) {
                            codaMandates(clientId:$clientId, excludeMandatesWithoutBankAccounts:true) {
                                id
                                clientId
                                fiduciaryId
                                bankId
                                bank {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                    isSignhereSupported
                                }
                                state
                                reasonCode
                                reasonNote
                                stateDate
                                bankAccounts{
                                    id
                                    type
                                    bankId
                                    state
                                    stateDate
                                    flowState
                                    bankMandateState
                                    iban
                                }
                                signHerePackage {
                                    packageStatus
                                    packageSignUrl
                                    packageRejectionReason
                                }
                                twikeyUrl
                                hasTeletransmissionPdf
                                reminders {
                                    date
                                    targetEmail
                                    type
                                }
                            }
                        }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                data.codaMandates.forEach(mandate => {
                    mandate.hasRedeliveryRequest = false;
                });
                this.codaMandates = data.codaMandates;
                this.codaLoaded = true;
                Loader.stop();
            },
            async submitOrderCodaMandateForm (values) {
                Loader.start();
                this.saving = true;

                const bankAccountInputsValid = await this.$refs.bankAccountInputs.validateBankAccounts();

                if (!bankAccountInputsValid) {
                    Loader.stop();
                    this.saving = false;
                    return;
                }

                // Build the list of bank accounts to order based on new BA inputs and existing BA checkboxes
                let bankAccountsToOrder = [];

                if (values.newBankAccounts && values.newBankAccounts.length > 0) {
                    values.newBankAccounts.forEach(ba => {
                        if (ba) {
                            bankAccountsToOrder.push({
                                'id': ba.replace(/\s+/g, ''),
                            });
                        }
                    });
                }

                if (values.existingBankAccounts && values.existingBankAccounts.length > 0) {
                    values.existingBankAccounts.forEach(ba => {
                        if (ba) {
                            bankAccountsToOrder.push({
                                'id': ba.replace(/\s+/g, ''),
                            });
                        }
                    });
                }

                const res = await this.$apollo.mutate({
                    mutation: gql`mutation OrderCodaMandate($input: CodaOrderingInput!) {
                        orderCodaMandate(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            fiduciaryId: this.currentEnvironment.id,
                            clientId: this.clientV2.id,
                            bankAccounts: bankAccountsToOrder,
                        },
                    },
                });
                const response = res.data.orderCodaMandate;

                Loader.stop();
                this.saving = false;

                if (response.errors) {
                    try {
                        await this.catchOrderCodaMandateErrors(response.errors);
                    } catch (err) {
                        await validate.notifyGQLValidationErrors(err);
                    }

                    return;
                }

                notify.success(this.$t('p-order-coda-success'));

                await this.getCodaMandates();

                this.closeOrderCodaMandatePopup();
            },
            catchOrderCodaMandateErrors (errors) {
                for (let i = 0; i < errors.length; i++) {
                    let error = errors[i];
                    if (error && error.source && error.source.pointer.match(/^\/data\/bankAccounts\/\d+\/id$/) && this.$refs.bankAccountInputs) {
                        let inputIndex = error.source.pointer.substring(19, 20);
                        this.$refs.orderCodaMandateForm.setErrors({
                            [`newBankAccounts[${inputIndex}]`]: 'err-' + error.code,
                        });

                        delete errors[i];
                    }
                }

                if (utils.count(errors)) {
                    return Promise.reject(errors);
                }

                return Promise.resolve();
            },
            openOrderCodaMandatePopup () {
                this.showOrderCodaMandatePopup = true;
            },
            closeOrderCodaMandatePopup () {
                this.ibanBankAccountExistsError = false;
                this.ibanBankAccountExistsForFiduError = false;
                this.showOrderCodaMandatePopup = false;
            },
            async getCodaRedeliveryRequests (clientId, codaMandates) {
                if (codaMandates.length > 0) {
                    const { data } = await this.$apollo.query({
                        query: gql`query codaRedeliverySearch($clientId: String, $mandates: [String]!, $state: [String]) {
                            codaRedeliverySearch(clientId: $clientId, codaMandates: $mandates, states: $state) {
                                mandate
                                bankAccount
                                state
                            }
                        }`,
                        variables: {
                            clientId: clientId,
                            mandates: codaMandates,
                            state: ['pending'],
                        },
                    });
                    data.codaRedeliverySearch.forEach(crr => {
                        const mandate = this.codaMandates.find(mandate => {
                            return mandate.id === crr.mandate;
                        });
                        if (mandate) {
                            mandate.hasRedeliveryRequest = true;
                        }
                    });
                }
            },
            isSubmitOrderCodaMandateDisabled (formValues) {
                const hasExistingBASelected = formValues.existingBankAccounts && formValues.existingBankAccounts.some(ba => !!ba);
                const hasNewBA = formValues.newBankAccounts && formValues.newBankAccounts.length > 0;
                return this.saving || (!hasNewBA && !hasExistingBASelected);
            },
        },
    };
</script>
<template>
    <div class='client-subppage'>
        <div class='panel panel-superwarning' id='coda-missing-contact-email-warning' v-if='missingContactEmail'>
            <div class='panel-heading'>
                <h3 class='panel-title'>
                    <i class='fa fa-info-circle'></i>
                </h3>
            </div>
            <div class='panel-body'>
                <p>
                    {{ $t('info-coda-missing-contact-email') }}
                </p>
            </div>
        </div>
        <div class='panel panel-superwarning' id='coda-invalid-representative-name-warning' v-if='invalidRepresentativeName'>
            <div class='panel-heading'>
                <h3 class='panel-title'>
                    <i class='fa fa-info-circle'></i>
                </h3>
            </div>
            <div class='panel-body'>
                <p>
                    {{ $t('info-coda-invalid-representative-name') }}
                </p>
            </div>
        </div>
        <content-box class='mb-6' :title='$t("ttl-mandates")'>
            <template #actions v-if='codaMandates'>
                <div class='flex'>
                    <ResendCodaButton
                        class='mr-3'
                        :client-v2='clientV2'
                        :bank-accounts='bankAccounts'
                    />

                    <FriendlyButton
                        label='btn-new-coda-mandate'
                        :action='openOrderCodaMandatePopup'
                        :disabled='missingContactEmail || invalidRepresentativeName'
                        square
                        extra-small
                        no-margin
                        id='orderCodaMandateButton'
                        symbol='plus'
                    />
                </div>
            </template>
            <div v-if='codaMandates' class='grid grid-cols-2 gap-6'>
                <div>
                    <div v-for='(mandate, index) in codaMandates' class='grid grid-cols-1 gap-6'>
                        <CodaCard
                            :mandate='mandate'
                            :key='index'
                            :selected='(highlightedCodaMandateId === mandate.id) && codaMandates.length > 1'
                            :animated='!!highlightedCodaMandateId'
                            @get-coda-mandates-event='getCodaMandates'
                            :id='"codaCard_"+mandate.id'
                            v-if='index % 2 === 0'
                        />
                    </div>
                </div>
                <div>
                    <div v-for='(mandate, index) in codaMandates' class='grid grid-cols-1 gap-6'>
                        <CodaCard
                            :mandate='mandate'
                            :key='index'
                            :selected='(highlightedCodaMandateId === mandate.id) && codaMandates.length > 1'
                            :animated='!!highlightedCodaMandateId'
                            @get-coda-mandates-event='getCodaMandates'
                            :id='"codaCard_"+mandate.id'
                            v-if='index % 2 !== 0'
                        />
                    </div>
                </div>
            </div>
            <tooltip big v-if='codaMandates && codaMandates.length === 0'>
                <template #trigger>
                    <span
                        class='py-1 px-3 rounded-full bg-green-300 inline-block bg-grey-300 bg-opacity-20'
                    >
                        {{ $t('state-no-coda') }}
                    </span>
                </template>
                <template #content>
                    {{ $t('p-no-coda') }}
                </template>
            </tooltip>
        </content-box>

        <SendSodaCodaByMail
            v-if='codaMandates && codaMandates.length > 0'
            :client-v2='clientV2'
            @clientupdated='$emit("clientupdated")'
        />

        <Form
            ref='orderCodaMandateForm'
            @submit='submitOrderCodaMandateForm'
            v-slot='{ values }'
        >
            <Popup
                :show='showOrderCodaMandatePopup'
                id='orderCodaMandatePopup'
                :close='() => { showOrderCodaMandatePopup = false }'
            >
                <template #header>
                    {{ $t('ttl-add-coda') }}
                </template>

                <ClientAddCoda
                    :client-v2='clientV2'
                    ref='bankAccountInputs'
                    :form-ref='$refs.orderCodaMandateForm'
                />

                <template #buttons>
                    <FriendlyButton
                        label='btn-cancel'
                        :action='closeOrderCodaMandatePopup'
                        square
                        extra-small
                        no-margin
                        secondary
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-save-mandate'
                        :disabled='isSubmitOrderCodaMandateDisabled(values)'
                        type='submit'
                        square
                        extra-small
                        no-margin
                    />
                </template>
            </Popup>
        </Form>
    </div>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-subppage-header .btn+.btn {
        @apply ml-3;
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }
</style>
