<script>
    import BankAccount from '@/components/BankAccount';
    import CodaState from '@/components/CodaState';
    import CodaMandateButtons from '@/components/CodaMandateButtons.vue';
    import SendCodaMandateByMailButton from '@/components/SendCodaMandateByMailButton';
    import Translated from '@/components/Translated';
    import Popup from '@/clientcomponents/Popup';

    export default {
        name: 'ImpactedCodaMandates',
        props: {
            mandates: {
                type: Array,
                default: () => [],
            },
            organizationDelegatesMandateDelivery: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            Popup,
            BankAccount,
            CodaState,
            CodaMandateButtons,
            SendCodaMandateByMailButton,
            Translated,
        },
        data: function () {
            return {
                show: false,
            };
        },
        emits: ['refreshCodaMandates'],
        methods: {
            close () {
                this.show = false;
            },
        },
    };
</script>

<template>
    <div>
        <Popup :show='show' :close='close' large>
            <template #header>
                {{ $t('h-impacted-coda-mandates-modal') }}
            </template>
            <p
                v-if='organizationDelegatesMandateDelivery'
            >
                <Translated>
                    <template #en>
                        For these CODA mandates, a new version of the document can be downloaded.<br>
                        For CODA mandates which can be signed digitally, the signing link is updated.<br>
                        For CODA mandates in state 'Awaiting signature' you can request a resend of the CODA mandate.
                    </template>
                    <template #nl>
                        Voor deze CODA volmachten kan een nieuwe versie van het document gedownload worden.<br>
                        Voor CODA volmachten die digitaal ondertekend kunnen worden, is de ondertekenlink bijgewerkt.<br>
                        Voor CODA volmachten in status ‘In afwachting van ondertekening’ kan je een aanvraag doen om de volmacht opnieuw te versturen.
                    </template>
                    <template #fr>
                        Une nouvelle version du document peut être téléchargée pour ces mandats CODA.<br>
                        Le lien de signature est mis à jour pour les mandats CODA qui peuvent être signés électroniquement.<br>
                        Vous pouvez demander un nouvel envoi du mandat CODA pour les mandats qui sont en status “En attente de signature”.
                    </template>
                </Translated>
            </p>
            <p v-else>
                <Translated>
                    <template #en>
                        For these CODA mandates, a new version of the document can be downloaded.<br>
                        For CODA mandates which can be signed digitally, the signing link is updated. Please proceed with sharing this new signing link.
                    </template>
                    <template #nl>
                        Voor deze CODA mandaten kan een nieuwe versie van het document gedowload worden.<br>
                        Voor CODA mandaten die digitaal ondertekend kunnen worden, is de ondertekenlink bijgewerkt. Ga verder met het delen van deze nieuwe ondertekenlink.
                    </template>
                    <template #fr>
                        Pour ces mandats CODA, une nouvelle version du document peut être téléchargée.<br>
                        Pour les mandats CODA qui peuvent être signés digitalement, le lien de signature a été mis à jour. Continuez à partager ce nouveau lien de signature.
                    </template>
                </Translated>
            </p>
            <div :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table'>
                    <colgroup>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th> {{ $t('th-accounts') }} </th>
                            <th> {{ $t('th-bank') }} </th>
                            <th> {{ $t('th-state') }} </th>
                            <th> {{ $t('th-actions') }} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(mandate, index) in mandates' :key='index'>
                            <td>
                                <BankAccount v-for='ba in mandate.bankAccounts' :key='ba.id' :bank-account='ba' />
                            </td>
                            <td>{{ mandate.bank.name }}</td>
                            <td>
                                <CodaState :mandate='mandate' :only-label='true' />
                            </td>
                            <td>
                                <div class='action'>
                                    <CodaMandateButtons :mandate='mandate' @refresh-coda-mandates='$emit("refreshCodaMandates")' />
                                </div>
                                <div class='action'>
                                    <SendCodaMandateByMailButton :mandate='mandate' v-if='mandate.state === "sent-client" && organizationDelegatesMandateDelivery' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template #buttons>
                <button type='button' class='btn btn-default' @click.prevent='close'>
                    {{ $t('btn-ok') }}
                </button>
            </template>
        </Popup>
    </div>
</template>

<style scoped>
.action {
    display: block;
    margin-bottom: 5px;
}

.modal-body-large p {
  max-width: none;
}
</style>
